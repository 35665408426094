import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestView = _resolveComponent("RequestView")

  return (!_ctx.state.isLoading)
    ? (_openBlock(), _createBlock(_component_RequestView, {
        key: 0,
        purpose: _ctx.state.purpose,
        kind: _ctx.RequestKindTypes.SecondOpinion,
        id: _ctx.state.id,
        onCancelRequest: _ctx.CancelRequest
      }, null, 8, ["purpose", "kind", "id", "onCancelRequest"]))
    : _createCommentVNode("", true)
}